import { Box, Button, Center, Wrap } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SUPPLIERS } from "../../constants/suppliers";
import { Supplier } from "./Supplier";

export function Suppliers() {
  const [areSuppliersVisible, setAreSuppliersVisible] = useState(false);

  const handleShowAllSuppliers = useCallback(() => {
    setAreSuppliersVisible(true);
  }, []);

  return (
    <Box>
      <Wrap spacing="2rem" justify="center">
        {SUPPLIERS.map((supplier, index) => (
          <Box
            display={{
              base: areSuppliersVisible
                ? "block"
                : index > 3
                ? "none"
                : "block",
              md: "block",
            }}
            key={index}>
            <Supplier supplier={supplier} />
          </Box>
        ))}
      </Wrap>

      {areSuppliersVisible === false && (
        <Center mt="2rem" display={{ base: "flex", md: "none" }}>
          <Button
            onClick={handleShowAllSuppliers}
            colorScheme="brandPrimary"
            borderColor="brandPrimary.300"
            variant="outline">
            <FormattedMessage defaultMessage="Tout voir" />
          </Button>
        </Center>
      )}
    </Box>
  );
}

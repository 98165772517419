/**
 * @typedef {Object} Props
 * @property {number} index
 * @property {string} src
 * @property {"left" | "right"} imagePosition
 * @property {string} [srcArrow]
 * @property {string} title
 * @property {import("react").ReactNode} description
 */

import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";

/**
 * @param {Props} props
 */
export function SaveStep({
  index,
  src,
  imagePosition,
  srcArrow,
  title,
  description,
}) {
  const intl = useIntl();

  return (
    <Box position="relative">
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing="3rem"
        alignItems="center">
        {imagePosition === "left" && (
          <Image
            src={src}
            display={{ base: "none", lg: "block" }}
            alt={title}
            loading="lazy"
          />
        )}

        <Stack spacing="1rem">
          <HStack spacing="1.5rem">
            <Text
              fontSize="3rem"
              fontWeight={600}
              lineHeight="normal"
              color="brandPrimary.300">
              {index}
            </Text>

            <Text
              fontSize="1.75rem"
              fontWeight={400}
              lineHeight="normal"
              color="brandPrimary.500">
              {title}
            </Text>
          </HStack>

          <Text
            fontSize="1.125rem"
            fontWeight={400}
            lineHeight="1.5rem"
            color="brandPrimary.400">
            {description}
          </Text>
        </Stack>

        <Image
          src={src}
          display={{
            base: "block",
            lg: imagePosition === "right" ? "block" : "none",
          }}
          alt={title}
          loading="lazy"
        />
      </Stack>

      {srcArrow && (
        <Image
          src={srcArrow}
          display={{ base: "none", lg: "block" }}
          position="absolute"
          bottom="0"
          left="50%"
          transform="translateX(-50%) translateY(100%)"
          loading="lazy"
          alt={intl.formatMessage({ defaultMessage: "Flèche" })}
        />
      )}
    </Box>
  );
}

import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Icon } from "../../components/Icon";

/**
 * @typedef {Object} Props
 * @property {import("../../constants/fieldsOfExpertise").FieldOfExpertise} fieldOfExpertise
 */
/**
 * @param {Props} props
 */
export function FieldOfExpertise({ fieldOfExpertise }) {
  const intl = useIntl();

  return (
    <HStack alignItems="flex-start" spacing="1rem">
      <Box>
        <Icon
          icon={fieldOfExpertise.icon}
          size="88px"
          color="rgba(155, 165, 214, 1)"
        />
      </Box>

      <Stack spacing="0.5rem">
        <Text fontSize="1.5rem" fontWeight={400} lineHeight="normal">
          {intl.formatMessage(fieldOfExpertise.name)}
        </Text>

        <Text fontSize="1rem" fontWeight={400} lineHeight="1.3125rem">
          {intl.formatMessage(fieldOfExpertise.description, { br: <br /> })}
        </Text>
      </Stack>
    </HStack>
  );
}

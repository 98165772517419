import { Box, Flex, Text } from "@chakra-ui/react";
import CenteredContent from "../CenteredContent";
import { Icon } from "../Icon";
import { Title } from "../Title";

/**
 * @typedef {Object} Props
 * @property {string | null} [icon]
 * @property {string} [title]
 * @property {string} [description]
 * @property {import("react").ReactNode} children
 */

/**
 * @param {Props} props
 */
export function PageLayout({ icon, title, description, children }) {
  return (
    <>
      {(title !== undefined || description !== undefined) && (
        <Box px="1rem" mt="2rem">
          <CenteredContent>
            <Flex>
              {icon !== null && icon !== undefined && (
                <Box
                  display={{ base: "none", xl: "block" }}
                  mr="1rem"
                  flexShrink={0}>
                  <Icon icon={icon} size="7rem" color="brandPrimary.300" />
                </Box>
              )}

              <Box>
                <Title>{title}</Title>

                <Text
                  as="h2"
                  fontSize="2rem"
                  fontWeight={500}
                  lineHeight="normal"
                  color="brandPrimary.300">
                  {description}
                </Text>
              </Box>
            </Flex>
          </CenteredContent>
        </Box>
      )}

      <Box mt={{ base: "1.5rem", xl: "3rem" }}>{children}</Box>
    </>
  );
}

import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";

/**
 * @typedef {object} Supplier
 * @property {string} name
 * @property {string} src
 */
/** @type {Supplier[]} */
export const SUPPLIERS = [
  {
    name: "engie",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/engie.png" },
    }),
  },
  {
    name: "total energies",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/total-energies.png" },
    }),
  },
  {
    name: "edf",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/edf.png" },
    }),
  },
  {
    name: "eni",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/eni.png" },
    }),
  },
  {
    name: "ekwateur",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/ekwateur.png" },
    }),
  },
  {
    name: "harmonie mutuelle",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/harmonie-mutuelle.png" },
    }),
  },
  {
    name: "aesio mutuelle",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/aesio-mutuelle.png" },
    }),
  },
  {
    name: "la mutuelle generale",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/la-mutuelle-generale.png" },
    }),
  },
  {
    name: "credit agricole",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/credit-agricole.png" },
    }),
  },
  {
    name: "axa",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/axa.png" },
    }),
  },
  {
    name: "mma",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/mma.png" },
    }),
  },
  {
    name: "maif",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/maif.png" },
    }),
  },
  {
    name: "free",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/free.png" },
    }),
  },
  {
    name: "orange",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/orange.png" },
    }),
  },
  {
    name: "bouygues telecom",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/bouygues-telecom.png" },
    }),
  },
  {
    name: "sfr",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/sfr.png" },
    }),
  },
  {
    name: "caisse d'epargne",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/caisse-d-epargne.png" },
    }),
  },
  {
    name: "credit mutuel",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/credit-mutuel.png" },
    }),
  },
  {
    name: "safti",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/safti.png" },
    }),
  },
  {
    name: "stephane plaza immobilier",
    src: generateFrontPath({
      id: "@front.internal-assets",
      parameters: { filePath: "suppliers/stephane-plaza-immobilier.png" },
    }),
  },
];

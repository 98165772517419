import { Box, Button, Center, DarkMode, Stack, Text } from "@chakra-ui/react";
import { RGrid } from "@raiden/library/components/RGrid";
import { POSTS_TYPE_VALUE_PAGE } from "@raiden/library/constants/posts";
import { useConfiguration } from "@raiden/library/hooks/useConfiguration";
import useTranslate from "@raiden/library/hooks/useTranslate";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { FormattedMessage, useIntl } from "react-intl";
import CenteredContent from "../../components/CenteredContent";
import { PageLayout } from "../../components/PageLayout";
import Seo from "../../components/Seo";
import { Title } from "../../components/Title";
import { FIELDS_OF_EXPERTISE } from "../../constants/fieldsOfExpertise";
import { useHeader } from "../../hooks/useHeader";
import { FieldOfExpertise } from "./FieldOfExpertise";
import { ReinsuranceItem } from "./ReinsuranceItem";
import { SaveStep } from "./SaveStep";
import { Suppliers } from "./Suppliers";

export function Home() {
  const intl = useIntl();

  const { height } = useHeader();

  const { configuration } = useConfiguration();

  const postHowDoesItWork = configuration.posts?.[POSTS_TYPE_VALUE_PAGE]?.find(
    (post) =>
      post.alias === process.env.NEXT_PUBLIC_POST_ALIAS_HOW_DOES_IT_WORK,
  );

  const translate = useTranslate();

  return (
    <>
      <Seo
        description={intl.formatMessage({
          defaultMessage:
            "Économies, gestion et suivi de tous vos contrats et abonnements, à vie.",
        })}
      />

      <PageLayout>
        <Box px="1rem">
          <CenteredContent>
            <Box
              backgroundImage={generateFrontPath({
                id: "@front.internal-assets",
                parameters: { filePath: "landing.svg" },
              })}
              backgroundPosition="bottom right"
              backgroundSize="100% auto"
              backgroundRepeat="no-repeat"
              pb={{ base: "12rem", sm: "20rem" }}>
              <Stack spacing="2rem" maxW="1074px">
                <Title>
                  <FormattedMessage defaultMessage="Économies, gestion et suivi de tous vos contrats et abonnements, à vie." />
                </Title>

                <Text variant="h3" color="brandPrimary.400">
                  <FormattedMessage
                    defaultMessage="Profitez de votre temps libre et faites des économies,{br}nous nous occupons de vos contrats pour vous."
                    values={{ br: <br /> }}
                  />
                </Text>

                <Box>
                  <NextLink
                    href={generateFrontPath({
                      id: "@front.contact",
                    })}
                    passHref>
                    <Button
                      as="a"
                      colorScheme="brandPrimary"
                      h="64px"
                      fontSize="1.4rem"
                      px="24px"
                      fontWeight={400}>
                      <FormattedMessage defaultMessage="Prendre rendez-vous" />
                    </Button>
                  </NextLink>
                </Box>
              </Stack>
            </Box>
          </CenteredContent>
        </Box>

        <Box
          id="test"
          scrollMarginTop={height}
          backgroundColor="brandPrimary.500"
          py="1.5rem"
          px="1rem">
          <CenteredContent>
            <DarkMode>
              <Text variant="h2">
                <FormattedMessage defaultMessage="Nos domaines d'expertise" />
              </Text>
            </DarkMode>
          </CenteredContent>
        </Box>

        <Box backgroundColor="brandPrimary.400" py="1.5rem" px="1rem">
          <CenteredContent>
            <DarkMode>
              <RGrid gridGap="59px" minCellWidth="min(450px, 100%)">
                {FIELDS_OF_EXPERTISE.map((fieldOfExpertise, index) => (
                  <FieldOfExpertise
                    fieldOfExpertise={fieldOfExpertise}
                    key={index}
                  />
                ))}
              </RGrid>
            </DarkMode>
          </CenteredContent>
        </Box>

        <Box mt="3rem" px="1rem">
          <CenteredContent>
            <Stack spacing="2rem">
              <Text
                fontSize="2rem"
                fontWeight={500}
                lineHeight="normal"
                color="brandPrimary.500">
                <FormattedMessage defaultMessage="Économisez en 3 étapes simples" />
              </Text>

              <Stack mt="2rem">
                <Stack spacing="3rem">
                  <SaveStep
                    index={1}
                    src={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "save-1.svg" },
                    })}
                    imagePosition="right"
                    srcArrow={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "save-arrow-left.svg" },
                    })}
                    title={intl.formatMessage({
                      defaultMessage: "Rendez-vous avec votre conseiller",
                    })}
                    description={intl.formatMessage(
                      {
                        defaultMessage:
                          "Vous prenez rendez-vous par téléphone ou via notre formulaire de contact.{br}Vous rencontrez votre conseiller lors d'un rendez-vous et discutez ensemble de <b>tous les contrats que vous souhaitez optimiser</b>.",
                      },
                      {
                        br: <br />,
                        b: (...chunks) => <b>{chunks}</b>,
                      },
                    )}
                  />

                  <SaveStep
                    index={2}
                    src={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "save-2.svg" },
                    })}
                    imagePosition="left"
                    srcArrow={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "save-arrow-right.svg" },
                    })}
                    title={intl.formatMessage({
                      defaultMessage: "Recherche par nos experts",
                    })}
                    description={intl.formatMessage(
                      {
                        defaultMessage:
                          "Votre conseiller compare gratuitement vos contrats et vous propose <b>des alternatives correspondantes à vos besoins</b>, si il trouve des offres plus intéressantes.{br}Vous êtes <b>libre</b> d'accepter les contrats que vous souhaitez, c'est <b>sans engagement</b>.",
                      },
                      {
                        br: <br />,
                        b: (...chunks) => <b>{chunks}</b>,
                      },
                    )}
                  />

                  <SaveStep
                    index={3}
                    imagePosition="right"
                    src={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "save-3.svg" },
                    })}
                    title={intl.formatMessage({
                      defaultMessage: "Accompagnement et suivi",
                    })}
                    description={intl.formatMessage(
                      {
                        defaultMessage:
                          "Nous nous occupons gratuitement <b>à votre place</b> de tout changement de fournisseur.{br}Vos contrats seront de nouveau <b>évalués à chaque fin d'engagement</b> ou si nous recevons de meilleures offres. Notre service reste <b>gratuit pour vous</b>, du début à la fin des démarches, notre rémunération étant assurée grâce à nos nombreux partenariats.",
                      },
                      {
                        br: <br />,
                        b: (...chunks) => <b>{chunks}</b>,
                      },
                    )}
                  />
                </Stack>
              </Stack>

              {postHowDoesItWork && (
                <Center>
                  <NextLink
                    href={generateFrontPath({
                      id: "@front.page",
                      parameters: {
                        postSlug: `${postHowDoesItWork.id}-${translate(
                          postHowDoesItWork.slug,
                        )}`,
                      },
                    })}
                    passHref>
                    <Button
                      as="a"
                      colorScheme="brandPrimary"
                      h="64px"
                      fontSize="1.4rem"
                      px="24px"
                      fontWeight={400}>
                      <FormattedMessage defaultMessage="En savoir plus" />
                    </Button>
                  </NextLink>
                </Center>
              )}
            </Stack>
          </CenteredContent>
        </Box>

        <Box backgroundColor="brandPrimary.100" mt="3rem" py="3rem" px="1rem">
          <CenteredContent>
            <Text variant="h2">
              <FormattedMessage defaultMessage="Pourquoi choisir ALL IN ONE ?" />
            </Text>

            <Box mt="2rem">
              <RGrid minCellWidth="min(500px, 100%)" gridGap="3rem">
                <ReinsuranceItem
                  icon="ms_check_circle"
                  title={intl.formatMessage({
                    defaultMessage: "Simple, gratuit et sans engagement",
                  })}
                  description={intl.formatMessage({
                    defaultMessage:
                      "Nos experts et conseillers gèrent votre dossier de A à Z",
                  })}
                />

                <ReinsuranceItem
                  icon="ms_sentiment_satisfied"
                  title={intl.formatMessage({
                    defaultMessage: "Vous économisez du temps et de l'argent",
                  })}
                  description={intl.formatMessage({
                    defaultMessage:
                      "Vous faites des économies et pouvez investir votre temps ailleurs",
                  })}
                />

                <ReinsuranceItem
                  icon="ms_all_inclusive"
                  title={intl.formatMessage({
                    defaultMessage: "Accompagnement à vie",
                  })}
                  description={intl.formatMessage({
                    defaultMessage:
                      "Vos contrats sont évalués régulièrement, sans intervention de votre part",
                  })}
                />

                <ReinsuranceItem
                  icon="ms_lock"
                  title={intl.formatMessage({
                    defaultMessage: "Votre espace privé",
                  })}
                  description={intl.formatMessage({
                    defaultMessage:
                      "Gérez tous vos contrats et fournisseurs sur une seule plateforme sécurisée",
                  })}
                  comingSoon={true}
                />
              </RGrid>
            </Box>
          </CenteredContent>
        </Box>

        <Box py="3rem" px="1rem">
          <CenteredContent>
            <Text variant="h2">
              <FormattedMessage defaultMessage="Les fournisseurs comparés" />
            </Text>

            <Box mt="2rem">
              <Suppliers />
            </Box>
          </CenteredContent>
        </Box>
      </PageLayout>
    </>
  );
}

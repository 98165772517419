import { defineMessage } from "react-intl";

/**
 * @typedef {object} FieldOfExpertise
 * @property {string} icon
 * @property {import("react-intl").MessageDescriptor} name
 * @property {import("react-intl").MessageDescriptor} description
 */

/**
 * @type {FieldOfExpertise[]}
 */
export const FIELDS_OF_EXPERTISE = [
  {
    icon: "ms_emoji_objects",
    name: defineMessage({ defaultMessage: "Énergie" }),
    description: defineMessage({
      defaultMessage:
        "Économisez en moyenne 350€/an sur vos factures d'électricité et de gaz. Adaptez votre contrat aux fluctuations du marché.",
    }),
  },
  {
    icon: "ms_policy",
    name: defineMessage({ defaultMessage: "Assurance" }),
    description: defineMessage({
      defaultMessage:
        "Optenez le meilleur contrat d'assurance habitation, auto, prêt.{br}Trouvez votre contrat mutuelle, responsabilité civile ou protection juridique",
    }),
  },
  {
    icon: "ms_smartphone",
    name: defineMessage({ defaultMessage: "Télécoms" }),
    description: defineMessage({
      defaultMessage:
        "Optimisez vos contrats de box internet, mobile et TV.{br}Obtenez des formules adaptées à vos besoins et effectuez jusqu'à 314€ d'économies par an.",
    }),
  },
  {
    icon: "ms_savings",
    name: defineMessage({ defaultMessage: "Finance" }),
    description: defineMessage({
      defaultMessage:
        "Optimisez votre déclaration fiscale et trouvez un prêt immobilier ou à la consommation.",
    }),
  },
];

import { Text } from "@chakra-ui/react";

/**
 * @typedef {Object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function Title({ children }) {
  return (
    <Text
      as="h1"
      fontSize={{ base: "2rem", xl: "3rem" }}
      fontWeight={{ base: 500, xl: 600 }}
      lineHeight="normal"
      color="brandPrimary.500">
      {children}
    </Text>
  );
}

import { Box, HStack, Stack, Tag, TagLabel, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../components/Icon";

/**
 * @typedef {Object} Props
 * @property {string} icon
 * @property {string} title
 * @property {string} description
 * @property {boolean} [comingSoon]
 */
/**
 * @param {Props} props
 */
export function ReinsuranceItem({ icon, title, description, comingSoon }) {
  return (
    <HStack spacing="1.5rem">
      <Box flexShrink={0}>
        <Icon icon={icon} size="3.5rem" color="#9BA5D6" />
      </Box>

      <Stack spacing="0.5rem">
        <HStack spacing="1rem">
          <Text flexGrow={1} variant="text-large">
            {title}
          </Text>

          {comingSoon && (
            <Tag variant="outline" borderRadius="full" color="brandPrimary.400">
              <TagLabel>
                <FormattedMessage defaultMessage="Prochainement" />
              </TagLabel>
            </Tag>
          )}
        </HStack>

        <Text variant="text-medium" color="brandPrimary.400">
          {description}
        </Text>
      </Stack>
    </HStack>
  );
}

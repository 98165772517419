/**
 * @typedef {Object} Props
 * @property {import("../../constants/suppliers").Supplier} supplier
 */

import { Image } from "@chakra-ui/react";

/**
 * @param {Props} props
 */
export function Supplier({ supplier }) {
  return (
    <Image
      src={supplier.src}
      maxW={{ base: "10rem", lg: "none" }}
      alt={supplier.name}
    />
  );
}
